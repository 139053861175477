import { OUTSIDE_OPEN_ERROR } from 'constants/error';
import { RequestArgs } from 'store/types/api';

// Fake API to return error if the app tries to
// make a request without having ZAFClient
// (e.g.: when opening the app outside Zendesk)
export const fallbackAPI = async (_args: RequestArgs) =>
  new Promise<Response>((_, rejects) => {
    return rejects({
      status: OUTSIDE_OPEN_ERROR.status,
      responseText: OUTSIDE_OPEN_ERROR.responseText,
    });
  });
