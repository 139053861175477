import CG_EXTENSION_REASONS from '../../../mocks/cg_extension_reasons.json';
import CG_EXTENSION_REFUSE_REASONS from '../../../mocks/cg_extension_refuse_reasons.json';
import {
  IConcreteGuaranteeExtensionReasons,
  IConcreteGuaranteeExtensionRefuseReasons,
} from '../contract';

export const extensionReasonsMapper = () => ({
  data: CG_EXTENSION_REASONS.content as IConcreteGuaranteeExtensionReasons,
});

export const extensionRefuseReasonsMapper = () => ({
  data: CG_EXTENSION_REFUSE_REASONS.content as IConcreteGuaranteeExtensionRefuseReasons,
});
