import { Middleware } from '@reduxjs/toolkit';
import ordersService from './api/entities/order/orders';
import orderService from 'store/api/entities/order/order';
import sellersService from './api/entities/seller/sellers';
import ticketService from './api/entities/ticket';
import { userService } from './api/entities/user';
import customerService from './api/entities/customer';
import messagesService from './api/entities/messages';
import transactionService from './api/entities/customerOrders/customerOrders';
import invoiceService from './api/entities/invoice';
import deliveryService from './api/entities/delivery';
import refundService from './api/entities/refund';
import concreteGuaranteeService from './api/entities/concreteGuarantee/concreteGuarantee';

const rtkSlices = [
  ticketService,
  userService,
  ordersService,
  orderService,
  sellersService,
  customerService,
  messagesService,
  transactionService,
  invoiceService,
  deliveryService,
  refundService,
  concreteGuaranteeService,
];

const rtkMiddlewares = rtkSlices.map((slice) => slice.middleware);

const middlewares = (getDefaultMiddleware: () => Middleware[]) =>
  getDefaultMiddleware().concat(rtkMiddlewares);

export default middlewares;
