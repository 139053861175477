// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from 'store/api/services';
import Routes from 'store/api/routes';

// Types
import { APIRequestTypes, Service } from 'store/types/api';
import { IMessages, IMessagesByOrderIdResponse } from 'store/types/messages';
import { UIError } from 'store/types/error';

// Constants
import { NameSpaces } from 'constants/namespaces';

const NAMESPACE = NameSpaces.MESSAGES;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getMessagesByOrderId: build[APIRequestTypes.QUERY]({
    query(arg: string) {
      // Get messages by order id
      const messagesByOrderIdUrl = Routes.messages.byOrderId.replace(
        ':orderId',
        arg
      );

      return { url: messagesByOrderIdUrl };
    },
    transformResponse: ({ content }: IMessagesByOrderIdResponse) => content,
    providesTags: (
      response: IMessages | undefined,
      _error: UIError | undefined,
      args: string
    ) => (response ? [{ type: NAMESPACE, id: args }] : []), // Use the orderId as key for the cache
  }),
});

const messagesService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const { useGetMessagesByOrderIdQuery } = messagesService;

export type MessagesService = typeof messagesService;

export default messagesService;
