import { NameSpaces } from 'constants/namespaces';
import { useGetUserQuery } from 'store/api/entities/user';

const NAMESPACE = NameSpaces.USER;

const useUser = () => {
  const {
    data: userData,
    isFetching: userFetching,
    error: userError,
  } = useGetUserQuery(`${NAMESPACE}/getUser`);

  const {
    externalId,
    locale,
    name,
    email,
    groups: userGroups,
    role,
  } = userData ?? {};

  return {
    externalId,
    locale,
    name,
    email,
    userData,
    userFetching,
    userError,
    userGroups,
    role,
  };
};
export default useUser;
