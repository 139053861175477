import { snakeToCamelObjectKeys } from 'utils/parsers';
import { IConcreteGuaranteesByOrderIdResponse } from '../contract';
import { ConcreteGuaranteesByOrderId } from './types';

export const byOrderIdMapper = ({
  content,
}: IConcreteGuaranteesByOrderIdResponse) =>
  snakeToCamelObjectKeys(
    content.concrete_guarantees
  ) as ConcreteGuaranteesByOrderId;
