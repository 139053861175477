// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from 'store/api/services';
import Routes from 'store/api/routes';
import {
  createRefundMapper,
  getRefundReasonsMapper,
  getRefundRemainingMapper,
  refundsSituationByCustomerOrderIdMapper,
} from './mappers';
import {
  createRefundRequester,
  getRefundReasonsRequester,
  getRefundRemainingRequester,
} from './requesters';

// Constants
import { NameSpaces } from 'constants/namespaces';

// Types
import { APIRequestTypes, Service } from 'store/types/api';
import {
  GetRefundsSituationByCustomerOrderIdRequest,
  GetRefundsSituationByCustomerOrderIdResponse,
} from './requesters/getRefundsSituationByCustomerOrderId.contract';
import { UIError } from 'store/types/error';
import { cancelRefundRequester } from './requesters/cancelRefund.request';

const NAMESPACE = NameSpaces.REFUND;

// New refund endpoints to be created here
const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getRefundsSituationByCustomerOrderId: build[APIRequestTypes.QUERY]({
    query(arg: GetRefundsSituationByCustomerOrderIdRequest) {
      const params = new URLSearchParams({
        seller_contract_ids: arg.sellerContractIds.map((id) => id.toString()),
      } as unknown as URLSearchParams).toString();

      // Get refund by order id
      const refundByCustomerOrderIdUrl =
        Routes.refund.byCustomerOrderId.replace(
          ':customerOrderId',
          arg.customerId
        );

      return {
        url: `${refundByCustomerOrderIdUrl}?${params}`,
      };
    },
    transformResponse: (
      response: GetRefundsSituationByCustomerOrderIdResponse
    ) => refundsSituationByCustomerOrderIdMapper(response),
    providesTags: (response, _error: UIError | undefined, args) =>
      response
        ? [
            { type: NAMESPACE, id: args.customerId },
            ...args.sellerContractIds.map((id) => ({
              type: NAMESPACE,
              id: `${args.customerId}_${id}`,
            })),
          ]
        : [],
  }),
  getRefundReasons: build[APIRequestTypes.QUERY]({
    query: getRefundReasonsRequester,
    transformResponse: getRefundReasonsMapper,
    providesTags: (response) => (response ? [{ type: NAMESPACE }] : []),
  }),
  getRefundRemaining: build[APIRequestTypes.QUERY]({
    query: getRefundRemainingRequester,
    transformResponse: getRefundRemainingMapper,
    providesTags: (response, _, args) =>
      response
        ? [
            {
              type: NAMESPACE,
              id: `${args.customerOrderId}_${args.sellerContractId}`,
            },
          ]
        : [],
  }),
  createRefund: build[APIRequestTypes.MUTATION]({
    query: createRefundRequester,
    transformResponse: createRefundMapper,
    invalidatesTags: (_, error, args) =>
      // invalidate cache only for real refunds, not dry run mode
      !error && !args.dryRun
        ? [
            {
              type: NAMESPACE,
              id: `${args.customerOrderId}_${args.sellerContractId}`,
            },
          ]
        : [],
  }),

  cancelRefund: build[APIRequestTypes.MUTATION]({
    query: cancelRefundRequester,
    invalidatesTags: (_, error, args) =>
      !error && args.customerOrderId
        ? [
            {
              type: NAMESPACE,
              id: args.customerOrderId,
            },
          ]
        : [],
  }),
});

const refundService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export type RefundService = typeof refundService;

export default refundService;
