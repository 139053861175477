import { CustomerOrderPaymentStatus } from 'store/api/entities/customerOrders/models';

export const paymentStatusMapper = (
  status: string
): CustomerOrderPaymentStatus | null => {
  switch (status) {
    case 'created':
      return 'created';

    case 'submitted':
      return 'submitted';

    case 'confirmed':
      return 'confirmed';

    case 'expired':
      return 'expired';

    case 'failed':
      return 'failed';

    case 'canceled':
      return 'canceled';

    default:
      return null;
  }
};
