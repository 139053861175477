// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from 'store/api/services';

// Types
import { APIRequestTypes, Service } from 'store/types/api';
import {
  appealReasonsMapper,
  byOrderIdMapper,
  byUUIDMapper,
  categoriesMapper,
  extensionReasonsMapper,
  extensionRefuseReasonsMapper,
  solutionsMapper,
} from './mappers';

// Constants
import { NameSpaces } from 'constants/namespaces';
import {
  approveAppealRequester,
  approveExtensionRequester,
  byOrderIdRequester,
  byUUIDRequester,
  categoriesRequester,
  deleteRequester,
  denyAppealRequester,
  denyExtensionRequester,
  extendRequester,
  openRequester,
  resolveRequester,
} from './requesters';

const NAMESPACE = NameSpaces.CONCRETE_GUARANTEE;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getConcreteGuaranteeByOrderId: build[APIRequestTypes.QUERY]({
    query: byOrderIdRequester,
    transformResponse: byOrderIdMapper,
    providesTags: (response) =>
      response
        ? [
            ...response.map(({ id }) => ({ type: NAMESPACE, id })),
            { type: NAMESPACE, id: 'LIST' },
          ]
        : [{ type: NAMESPACE, id: 'LIST' }],
  }),

  getConcreteGuaranteeById: build[APIRequestTypes.QUERY]({
    query: byUUIDRequester,
    transformResponse: byUUIDMapper,
    providesTags: (_response, _error, arg) => [{ type: NAMESPACE, id: arg.id }],
  }),

  getConcreteGuaranteeCategories: build[APIRequestTypes.QUERY]({
    query: categoriesRequester,
    transformResponse: categoriesMapper,
    providesTags: () => [{ type: `${NAMESPACE}-motives`, id: 'LIST' }],
  }),

  // For now we're getting the values on the UI.
  // To be migrated to the BE in the future
  getConcreteGuaranteeSolutions: build[APIRequestTypes.QUERY]({
    queryFn: solutionsMapper,
    providesTags: (response) =>
      response ? [{ type: `${NAMESPACE}-solutions`, id: 'LIST' }] : [],
  }),

  // For now we're getting the values on the UI.
  // To be migrated to the BE in the future
  getConcreteGuaranteeExtensionReasons: build[APIRequestTypes.QUERY]({
    queryFn: extensionReasonsMapper,
    providesTags: (response) =>
      response ? [{ type: `${NAMESPACE}-extension-reasons`, id: 'LIST' }] : [],
  }),
  // For now we're getting the values on the UI.
  // To be migrated to the BE in the future
  getConcreteGuaranteeExtensionRefuseReasons: build[APIRequestTypes.QUERY]({
    queryFn: extensionRefuseReasonsMapper,
    providesTags: (response) =>
      response
        ? [{ type: `${NAMESPACE}-extension-refuse-reasons`, id: 'LIST' }]
        : [],
  }),
  // For now we're getting the values on the UI.
  // To be migrated to the BE in the future
  getConcreteGuaranteeAppealReasons: build[APIRequestTypes.QUERY]({
    queryFn: appealReasonsMapper,
    providesTags: (response) =>
      response ? [{ type: `${NAMESPACE}-appeal-reasons`, id: 'LIST' }] : [],
  }),

  resolveConcreteGuarantee: build[APIRequestTypes.MUTATION]({
    query: resolveRequester,
    invalidatesTags: (_result, error, args) =>
      !error
        ? [
            { type: NAMESPACE, id: args.id },
            { type: NAMESPACE, id: 'LIST' },
          ]
        : [],
  }),

  createConcreteGuarantee: build[APIRequestTypes.MUTATION]({
    query: openRequester,
    invalidatesTags: (_result, error) =>
      !error ? [{ type: NAMESPACE, id: 'LIST' }] : [],
  }),

  deleteConcreteGuarantee: build[APIRequestTypes.MUTATION]({
    query: deleteRequester,
    invalidatesTags: (_result, error, { id }) =>
      !error
        ? [
            { type: NAMESPACE, id },
            { type: NAMESPACE, id: 'LIST' },
          ]
        : [],
  }),

  approveExtensionRequest: build[APIRequestTypes.MUTATION]({
    query: approveExtensionRequester,
    invalidatesTags: (_result, error, args) =>
      !error ? [{ type: NAMESPACE, id: args.id }] : [],
  }),

  denyExtensionRequest: build[APIRequestTypes.MUTATION]({
    query: denyExtensionRequester,
    invalidatesTags: (_result, error, args) =>
      !error ? [{ type: NAMESPACE, id: args.id }] : [],
  }),

  extendCgDeadlines: build[APIRequestTypes.MUTATION]({
    query: extendRequester,
    invalidatesTags: (_result, error, args) =>
      !error ? [{ type: NAMESPACE, id: args.id }] : [],
  }),

  approveAppealRequest: build[APIRequestTypes.MUTATION]({
    query: approveAppealRequester,
    invalidatesTags: (_result, error, args) =>
      !error ? [{ type: NAMESPACE, id: args.id }] : [],
  }),

  denyAppealRequest: build[APIRequestTypes.MUTATION]({
    query: denyAppealRequester,
    invalidatesTags: (_result, error, args) =>
      !error ? [{ type: NAMESPACE, id: args.id }] : [],
  }),
});

const concreteGuaranteeService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const {
  useGetConcreteGuaranteeByOrderIdQuery,
  useGetConcreteGuaranteeByIdQuery,
  useGetConcreteGuaranteeCategoriesQuery,
  useGetConcreteGuaranteeSolutionsQuery,
  useGetConcreteGuaranteeExtensionReasonsQuery,
  useGetConcreteGuaranteeExtensionRefuseReasonsQuery,
  useGetConcreteGuaranteeAppealReasonsQuery,
  useResolveConcreteGuaranteeMutation,
  useCreateConcreteGuaranteeMutation,
  useDeleteConcreteGuaranteeMutation,
  useApproveExtensionRequestMutation,
  useDenyExtensionRequestMutation,
  useExtendCgDeadlinesMutation,
  useApproveAppealRequestMutation,
  useDenyAppealRequestMutation,
} = concreteGuaranteeService;

export type ConcreteGuaranteeService = typeof concreteGuaranteeService;

export default concreteGuaranteeService;
