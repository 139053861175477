export enum NameSpaces {
  TICKET = 'ticket',
  USER = 'user',
  ORDERS = 'orders',
  SELLERS = 'sellers',
  CUSTOMER = 'customer',
  TRANSACTION = 'transaction',
  MESSAGES = 'messages',
  INVOICE = 'invoice',
  REFUND = 'refund',
  CONCRETE_GUARANTEE = 'concrete-guarantee',
  COUPONS = 'coupons',
  RETURNS = 'returns',
  CORT = 'cort',
  CONFIG = 'config',
}
