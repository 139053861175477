import { GetSellersByOrderIdResponse } from 'store/api/entities/seller/requesters/getSellersByOrderId.contract';
import { Seller } from 'store/api/entities/seller/model/Seller';

export const getSellerByOrderIdMapper = ({
  content,
}: GetSellersByOrderIdResponse): Seller[] => {
  return content.map((seller) => ({
    id: seller.id,
    name: seller.name,
    email: seller.email,
    number: seller.number,
    messagesCount: seller.messagesCount,
    returnsOnManomanoSide: seller.returns_on_manomano_side,
    returnPolicy: seller.return_policy
      ? {
          withdrawalLimit: seller.return_policy.withdrawal_limit,
          refundLimit: seller.return_policy.refund_limit,
          offersFreeReturns: seller.return_policy.offers_free_returns,
        }
      : undefined,
    holidays: seller.holidays
      ? {
          endsAt: seller.holidays.ends_at,
          startsAt: seller.holidays.starts_at,
        }
      : undefined,
    company: seller.company
      ? {
          name: seller.company.name,
          siren: seller.company.siren,
          vat: seller.company.vat,
        }
      : undefined,
  }));
};
