import Routes from 'store/api/routes';
import { RequestMethods } from 'store/types/api';
import { DeleteCGRequestArgs } from '../contract';

export const deleteRequester = ({ body, id }: DeleteCGRequestArgs) => {
  const url = Routes.concreteGuarantee.delete.replace(':cgId', id.toString());

  return {
    url,
    method: RequestMethods.DELETE,
    body,
  };
};
