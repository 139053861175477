import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { NameSpaces } from 'constants/namespaces';
import { zafApi } from 'store/api/services';
import { APIRequestTypes, ServerResponses, Service } from 'store/types/api';
import { ICurrentUser, ICurrentUserResponse } from 'store/types/user';

const NAMESPACE = NameSpaces.USER;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getUser: build[APIRequestTypes.QUERY]({
    query: () => ({ url: `currentUser` }),
    transformResponse: (response: ServerResponses) => {
      const { currentUser } = response as ICurrentUserResponse;

      return currentUser;
    },
    providesTags: (response?: ICurrentUser) =>
      response ? [{ type: NAMESPACE, id: response.id }] : [],
  }),
});

export const userService = zafApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const { useGetUserQuery } = userService;

export type UserService = typeof userService;
