import { OUTSIDE_OPEN_ERROR } from 'constants/error';
import { IServerError } from 'store/types/error';

export enum ErrorAppCodes {
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export const customerNotFoundMapper: (error: IServerError) => boolean = (
  error
) => {
  const { responseJSON } = error ?? {};

  const jsonError = responseJSON?.error;

  return (
    [ErrorAppCodes.NOT_FOUND, ErrorAppCodes.INTERNAL_SERVER_ERROR].includes(
      jsonError?.app_code as ErrorAppCodes
    ) &&
    [
      /Customer (\d+) Not Found/i,
      /Could not fetch customer order id for order id (\d+) from order_association table/i,
    ].some((regex) => regex.test(jsonError.message ?? ''))
  );
};

export const outsideOpenError: (error: IServerError) => boolean = (error) => {
  const { status } = error ?? {};

  return status === OUTSIDE_OPEN_ERROR.status;
};

export const cgNoTicketForOrderError: (error: IServerError) => boolean = (
  error
) => {
  const { responseJSON } = error ?? {};

  const jsonError = responseJSON?.error;

  return (
    jsonError?.app_code === ErrorAppCodes.NOT_FOUND &&
    /No ticket with order reference (M\d+) was found/i.test(
      jsonError.message ?? ''
    )
  );
};

export const ticketNotFoundMapper: (error: IServerError) => boolean = (
  error
) => {
  const { responseJSON } = error ?? {};

  const jsonError = responseJSON?.error;

  return (
    ErrorAppCodes.INTERNAL_SERVER_ERROR === jsonError?.app_code &&
    /\[503 Service Unavailable\].*care\/api\/v1\/tickets\/search/i.test(
      jsonError.message ?? ''
    )
  );
};
