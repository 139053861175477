import { zafApi } from 'store/api/services';
import { APIRequestTypes, ServerResponses, Service } from 'store/types/api';
import {
  IOrder,
  ITicket,
  ITicketOrderResponse,
  ITicketResponse,
} from 'store/types/ticket';
import { NameSpaces } from 'constants/namespaces';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

const NAMESPACE = NameSpaces.TICKET;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getTicket: build[APIRequestTypes.QUERY]({
    query: () => ({
      url: `ticket`,
    }),
    transformResponse: (response: ServerResponses) => {
      const {
        ticket: { requester },
      } = response as ITicketResponse;

      const data: ITicket = {
        locale: requester.locale,
        customerId: requester.externalId,
        customerEmail: requester.email,
      };

      return data;
    },
    providesTags: (response?: ITicket) =>
      response ? [{ type: NAMESPACE, id: response.customerId }] : [],
  }),
  getTicketOrder: build[APIRequestTypes.QUERY]({
    query: () => ({ url: window.env.ORDER_FIELD as string }),
    transformResponse: (response: ServerResponses) => {
      const data: IOrder = {
        orderRef: (response as ITicketOrderResponse)[
          window.env.ORDER_FIELD as string
        ],
      };

      return data;
    },
    providesTags: (response?: IOrder) =>
      response ? [{ type: NAMESPACE, id: response.orderRef }] : [],
  }),
});

const ticketService = zafApi.injectEndpoints({
  endpoints,
  overrideExisting: false,
});

export const { useGetTicketQuery, useGetTicketOrderQuery } = ticketService;

export type TicketService = typeof ticketService;

export default ticketService;
