import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useRoutes,
} from 'react-router-dom';
import {
  init,
  wrapUseRoutes,
  BrowserTracing,
  reactRouterV6Instrumentation,
} from '@sentry/react';

export const initSentry = () =>
  init({
    dsn: window.env.SENTRY_DSN as string,
    environment: window.env.ENV as string,
    ...(APP_NAME &&
      APP_VERSION && {
        release: `${APP_NAME}@${APP_VERSION}`,
      }),
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 0.3,
  });

export const useSentryRoutes = wrapUseRoutes(useRoutes);
