import { snakeToCamelObjectKeys } from 'utils/parsers';
import { IConcreteGuaranteeByUUIDResponse } from '../contract';
import { ConcreteGuaranteeById } from './types';

export const byUUIDMapper = ({ content }: IConcreteGuaranteeByUUIDResponse) => {
  const parsedResponse = snakeToCamelObjectKeys(
    content
  ) as unknown as ConcreteGuaranteeById;

  return {
    ...parsedResponse,
  };
};
