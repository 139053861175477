// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from 'store/api/services';
import Routes from 'store/api/routes';

// Types
import { APIRequestTypes, RequestMethods, Service } from 'store/types/api';
import {
  Customer,
  EditCustomerInfoRequestArgs,
  EditCustomerInfoResponse,
  ICustomerResponse,
  SearchCustomersResponse,
} from 'store/types/customer';
import {
  ActivateCustomerRequestArgs,
  DeactivateCustomerRequestArgs,
} from './contract';

// Constants
import { NameSpaces } from 'constants/namespaces';

// Utils
import { toCamelCaseObjectKeys } from 'utils/parsers';

const NAMESPACE = NameSpaces.CUSTOMER;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getCustomerById: build[APIRequestTypes.QUERY]({
    query(arg: string) {
      const url = Routes.customer.byId.replace(':customerId', arg);
      return { url };
    },
    transformResponse: ({ content }: ICustomerResponse): Customer => {
      return toCamelCaseObjectKeys(content) as unknown as Customer;
    },
    providesTags: (response?: Customer) =>
      response ? [{ type: NAMESPACE, id: response.id }] : [],
  }),
  getCustomersByEmail: build[APIRequestTypes.QUERY]({
    query({
      email,
      page,
      limit,
    }: {
      email: string;
      page: number;
      limit: number;
    }) {
      const url = Routes.customer.customersByEmail
        .replace(':page', page.toString())
        .replace(':limit', limit.toString())
        .replace(':email', email);

      return { url };
    },
    transformResponse: ({ content, pagination }: SearchCustomersResponse) => ({
      customers: content,
      pagination,
    }),
    providesTags: (response, _error, { email }) =>
      response ? [{ type: NAMESPACE, id: email }] : [],
  }),
  getCustomersByName: build[APIRequestTypes.QUERY]({
    query({
      firstname,
      lastname,
      page,
      limit,
    }: {
      page: number;
      limit: number;
      firstname: string;
      lastname: string;
    }) {
      const url = Routes.customer.customersByName
        .replace(':page', page.toString())
        .replace(':limit', limit.toString())
        .replace(':firstname', firstname)
        .replace(':lastname', lastname);

      return { url };
    },
    transformResponse: ({ content, pagination }: SearchCustomersResponse) => ({
      customers: content,
      pagination,
    }),
    providesTags: (response, _error, { firstname, lastname }) =>
      response ? [{ type: NAMESPACE, id: `${firstname}-${lastname}` }] : [],
  }),
  editCustomerInformation: build[APIRequestTypes.MUTATION]<
    EditCustomerInfoResponse,
    EditCustomerInfoRequestArgs
  >({
    query: (args) => ({
      url: Routes.customer.byIdOld.replace(
        ':customerId',
        args.id?.toString() ?? ''
      ),
      method: RequestMethods.PUT,
      body: args,
    }),
    invalidatesTags: (response, _error, args) =>
      response ? [{ type: NAMESPACE, id: args.id }] : [],
  }),
  resetPassword: build[APIRequestTypes.QUERY]({
    query(arg: string) {
      const url = Routes.customer.resetPassword.replace(':email', arg);
      return { url };
    },
    providesTags: (response, _error, email) =>
      response ? [{ type: NAMESPACE, id: `reset-password-${email}` }] : [],
  }),

  activateCustomer: build[APIRequestTypes.MUTATION]<
    {
      status: number;
      data?: void;
    },
    ActivateCustomerRequestArgs
  >({
    query: (args) => ({
      url: Routes.customer.activate.replace(':id', args.id?.toString() ?? ''),
      method: RequestMethods.PUT,
    }),
    invalidatesTags: (_, error, args) =>
      error ? [] : [{ type: NAMESPACE, id: args.id }],
  }),

  deactivateCustomer: build[APIRequestTypes.MUTATION]<
    {
      status: number;
      data?: void;
    },
    DeactivateCustomerRequestArgs
  >({
    query: (args) => ({
      url: Routes.customer.deactivate.replace(':id', args.id?.toString() ?? ''),
      method: RequestMethods.DELETE,
    }),
    invalidatesTags: (_, error, args) =>
      error ? [] : [{ type: NAMESPACE, id: args.id }],
  }),
});

const customerService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const {
  useGetCustomerByIdQuery,
  useEditCustomerInformationMutation,
  useLazyResetPasswordQuery,
  useGetCustomersByEmailQuery,
  useGetCustomersByNameQuery,
  useActivateCustomerMutation,
  useDeactivateCustomerMutation,
} = customerService;

export type CustomerService = typeof customerService;

export default customerService;
