import Routes from 'store/api/routes';
import { RequestMethods } from 'store/types/api';
import { ApproveAppealRequestArgs, DenyAppealRequestArgs } from '../contract';

export const approveAppealRequester = ({
  id,
  ...body
}: ApproveAppealRequestArgs) => {
  const url = Routes.concreteGuarantee.appeal.approve.replace(':uuid', id);

  return {
    url,
    method: RequestMethods.PUT,
    body,
  };
};

export const denyAppealRequester = ({ id, ...body }: DenyAppealRequestArgs) => {
  const url = Routes.concreteGuarantee.appeal.deny.replace(':uuid', id);

  return {
    url,
    method: RequestMethods.PUT,
    body,
  };
};
