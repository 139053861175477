import { GetRefundRemainingResponse } from '../requesters';
import { RefundProductRemaining } from 'store/api/entities/refund/models/RefundProductRemaining';
import { priceMapper } from 'store/api/entities/shared/mappers';

export const getRefundRemainingMapper = ({
  content,
}: GetRefundRemainingResponse): RefundProductRemaining[] =>
  content.items.map(
    (item) =>
      ({
        productOrderId: item.product_order_id,
        productMaxRefundable: priceMapper(
          item.product_max_refundable.amount,
          item.product_max_refundable.currency
        ),
        shippingMaxRefundable: priceMapper(
          item.shipping_max_refundable.amount,
          item.shipping_max_refundable.currency
        ),
        quantityRefundable: item.quantity_refundable,
      } as RefundProductRemaining)
  );
