import Routes from 'store/api/routes';
import { GetRefundReasonsRequestArgs } from './getRefundReasons.contract';
import { ConfigDetails } from '../../types';
import refundService from '../refund';
import { RefundChannel } from '../models';

export const getRefundReasonsRequester = ({
  category,
}: GetRefundReasonsRequestArgs) => ({
  url: `${Routes.refund.reasons}?category=${category}`,
});

type GetRefundReasonsRequest = {
  refundChannel: RefundChannel;
};

export const useGetRefundReasons = (
  config: ConfigDetails<GetRefundReasonsRequest>
) => {
  const category: GetRefundReasonsRequestArgs['category'] =
    config.args.refundChannel === RefundChannel.agentRefund
      ? 'AGENT_REFUND'
      : 'EXTREME_CASE';

  const response = refundService.useGetRefundReasonsQuery(
    { category },
    {
      ...config.options,
    }
  );

  return {
    ...response,
  };
};
