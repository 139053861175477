import { useEffect } from 'react';

// Custom Hooks
import { useWindow } from 'client/hooks/features/crossWindow';
import { useUser } from 'client/hooks/entities/user';

// Components
import ScreenDiscriminatorLayout from 'client/layouts/ScreenDiscriminatorLayout';

// Store
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setUserProperties } from 'store/api/entities/user';

// Styles
import 'client/assets/styles/global.scss';

function App() {
  const {
    addOnDesactivateWindowEventListener,
    addOnNewMessageEventListener,
    removeOnDesactivateWindowEventListener,
    closeWideScreen,
  } = useWindow();

  const { externalId, userFetching, email = '' } = useUser();
  const userExternalId = useAppSelector((state) => state.userSlice?.externalId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Save the external id into the slice, so it can be
    // used for all MSF requests thereafter
    if (!userFetching && !userExternalId && externalId) {
      dispatch(
        setUserProperties([
          {
            key: 'externalId',
            value: externalId,
          },
          {
            key: 'email',
            value: email,
          },
        ])
      );
    }
  }, [dispatch, email, externalId, userExternalId, userFetching]);

  useEffect(() => {
    window.addEventListener('unload', closeWideScreen);

    addOnDesactivateWindowEventListener();

    return () => {
      window.removeEventListener('unload', closeWideScreen);
      removeOnDesactivateWindowEventListener();
    };
  });

  function handleMessage(event: object) {
    /* eslint-disable*/
    console.log('handleMessage', event);
  }

  useEffect(() => {
    return addOnNewMessageEventListener(handleMessage);
  });

  return (
    <div className="App">
      <ScreenDiscriminatorLayout />
    </div>
  );
}

export default App;
