import { RootState } from 'store';
import * as Sentry from '@sentry/browser';
import {
  RequestArgs,
  RequestMethods,
  Service,
  ServiceProviders,
} from 'store/types/api';
import { IServerError } from 'store/types/error';
import { errorHandler } from 'store/utils/error';
import { fallbackAPI } from 'store/utils/api';
import zafClient from './zafClient';

let zaf = zafClient ? zafClient : window.opener?.client;

// To handle requests when the app is opened
// outside Zendesk and we don't have the client
// to make API's requests
if (!zaf) {
  zaf = {
    get: fallbackAPI,
    request: fallbackAPI,
  };
}

const services: {
  [ServiceProviders.ZAF]: Service;
  [ServiceProviders.MSF]: Service;
  [ServiceProviders.PUBLIC]: Service;
} = {
  [ServiceProviders.ZAF]: async (args: RequestArgs) => {
    try {
      const { url } = args;

      const response = await zaf.get(url);

      return { data: response };
    } catch (error: unknown) {
      return { error: errorHandler(error as IServerError, args) };
    }
  },

  [ServiceProviders.MSF]: async (args: RequestArgs, { getState }) => {
    const { API_URL, DNS } = window.env;

    const { url, method = RequestMethods.GET, body = {} } = args;
    let { externalId = '', email = '' } =
      (getState() as RootState)?.userSlice ?? {};

    const shouldSendBody = method !== RequestMethods.GET;

    try {
      // A fallback in case we weren't able to get the data when the app was mounted
      if (shouldSendBody && (!externalId || !email)) {
        const zdUser = await zaf.get('currentUser');

        const { currentUser } = zdUser;

        if (!currentUser?.externalId && !currentUser?.email) {
          Sentry.captureException({
            title: 'Agent not found',
            data: JSON.stringify(zdUser),
          });
        }

        email = currentUser?.email ?? email;
        externalId = currentUser?.externalId ?? externalId;
      }

      const response = await zaf.request({
        url: `${API_URL}${url}`,
        type: method,
        secure: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization: '{{setting.token}}',
          'x-mm-agent-external-id': externalId,
          'x-mm-agent-email': email,
          'Access-Control-Allow-Origin': DNS,
        },
        data: shouldSendBody
          ? JSON.stringify({
              agent_email: email,
              agent_id: externalId,
              ...body,
            })
          : null,
      });

      return { data: response };
    } catch (error: unknown) {
      return { error: errorHandler(error as IServerError, args) };
    }
  },
  [ServiceProviders.PUBLIC]: async (args: RequestArgs) => {
    const { url, method = RequestMethods.GET, body = {}, headers = {} } = args;

    try {
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json', ...headers },
        body: Object.keys(body).length ? JSON.stringify(body) : null,
      });

      const json = await response.json();

      if (!response.ok) {
        throw new Error(json);
      }

      return { data: json };
    } catch (error: unknown) {
      return { error: errorHandler(error as IServerError, args) };
    }
  },
};

export const requestBuilder = ({
  serviceProvider,
}: {
  serviceProvider: ServiceProviders;
}) => services[serviceProvider];
