import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import { SnakeCasedPropertiesDeep } from 'type-fest';

export const capitalizeFirstLetter: (text?: string) => string = (text) => {
  if (typeof text !== 'string' || !text.length) return '';

  const firstLetter = text.charAt(0);

  return text.replace(firstLetter, firstLetter.toUpperCase());
};

export const getOrderIdFromRef = (orderRef: string | number) => {
  if (typeof orderRef === 'string' && orderRef.startsWith('M')) {
    return Number(orderRef.substring(5));
  }

  return orderRef as number;
};

export const toTitleCase: (text: string) => string = (text) => {
  return capitalizeFirstLetter(text).replaceAll('_', ' ');
};

export const toSnakeCase: (text: string) => string = (text) => {
  return text.toLowerCase().replaceAll(' ', '_');
};

export const snakeToCamel = (myString: string) =>
  myString.replace(/(_\w)/g, function (m) {
    return m[1].toUpperCase();
  });

/**
 * @deprecated prefer to use toCamelCaseObjectKeys
 * @param mySnakeCase
 */
export const snakeToCamelObjectKeys = <T extends object>(mySnakeCase: T) => {
  if (typeof mySnakeCase !== 'object' || !mySnakeCase) return mySnakeCase;

  const entries = Object.entries(mySnakeCase);

  const mappedEntries: [string, string | CamelizeObjectKeys<object>][] =
    entries.map(([key, value]) => [
      snakeToCamel(key),
      snakeToCamelObjectKeys(value),
    ]);

  return Array.isArray(mySnakeCase)
    ? Array.from(mappedEntries.map(([, value]) => value))
    : (Object.fromEntries(mappedEntries) as CamelizeObjectKeys<T>);
};

export const toCamelCaseObjectKeys = <T extends object>(
  input: T | T[]
): CamelizeObjectKeys<T> | CamelizeObjectKeys<T>[] => {
  if (typeof input !== 'object' || input === null) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map(toCamelCaseObjectKeys) as CamelizeObjectKeys<T>[];
  }

  return Object.keys(input).reduce(
    (acc: Record<string, unknown>, key: string) => {
      const camelCaseKey = camelCase(key);
      const value = input[key as keyof object];

      if (typeof value === 'function') {
        acc[camelCaseKey] = value;
      } else {
        acc[camelCaseKey] = toCamelCaseObjectKeys(value as object);
      }

      return acc;
    },
    {}
  ) as CamelizeObjectKeys<T>;
};

export const toSnakeCaseObjectKeys = <T = object>(
  input: object | object[]
): SnakeCasedPropertiesDeep<T> | SnakeCasedPropertiesDeep<T>[] => {
  if (typeof input !== 'object' || input === null) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map(toSnakeCaseObjectKeys) as SnakeCasedPropertiesDeep<T>[];
  }

  return Object.keys(input).reduce((acc, key: string) => {
    const snakeCaseKey = snakeCase(key);
    const value = input[key as keyof object];

    if (typeof value === 'function') {
      acc[snakeCaseKey] = value;
    } else {
      acc[snakeCaseKey] = toSnakeCaseObjectKeys(value as object);
    }

    return acc;
  }, {} as Record<string, unknown>) as SnakeCasedPropertiesDeep<T>;
};

export const parseUrlSearchParamsToSnakeCaseObject = <
  T extends URLSearchParams
>(
  params: T
) => {
  const args: Record<string, string> = {};

  params.forEach((value, key) => {
    args[key] = value;
  });

  return toSnakeCaseObjectKeys<T>(args) as SnakeCasedPropertiesDeep<T>;
};

export const serializeQueryParams = (params: URLSearchParams) => {
  const args: Record<string, string> = {};

  params.forEach((value, key) => {
    args[key] = value;
  });

  return args;
};

export const mapIdToLabel = ({
  searchArr,
  id,
}: {
  searchArr: { id: string | number; label: string }[];
  id: string | number;
}) => searchArr.find((item) => item.id === id)?.label ?? '';
