import Routes from 'store/api/routes';
import { ConcreteGuaranteeCategoriesType } from '../contract';

export const categoriesRequester = ({
  categories,
}: {
  categories: ConcreteGuaranteeCategoriesType;
}) => ({
  url: `${Routes.concreteGuarantee.motives}?categories=${categories}`,
});
