import {
  RefundSituation,
  RefundStatus,
} from 'store/api/entities/refund/models';

export const isNil: (value: unknown) => boolean = (value) =>
  value === null || value === undefined;

export const isEmptyObject: (objectToValidate: unknown) => boolean = (
  objetToValidate
) =>
  !isNil(objetToValidate) &&
  !Array.isArray(objetToValidate) &&
  typeof objetToValidate === 'object' &&
  Object.keys(objetToValidate as object).length === 0;

export const isFunction: (functionTovalidate: unknown) => boolean = (
  functionTovalidate
) => typeof functionTovalidate === 'function';

export const isString: (value: unknown) => boolean = (value) =>
  typeof value === 'string';

export const isLastArrayIndex: (index: number, array: unknown[]) => boolean = (
  index,
  array
) => index === array.length - 1;

export const isFirstArrayIndex: (index: number) => boolean = (index) =>
  index === 0;

export function isRefundComplete(refunds: RefundSituation[]) {
  return refunds?.some((history) => history.status !== RefundStatus.created);
}

export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null;
}
