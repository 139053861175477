import Routes from 'store/api/routes';
import { RequestArgs, RequestMethods } from 'store/types/api';
import { toSnakeCaseObjectKeys } from 'utils/parsers';
import {
  CreateRefundRequestArgs,
  CreateRefundRequestItemByAmount,
  CreateRefundRequestItemByQuantity,
} from './createRefund.contract';
import refundService from '../refund';
import { CamelCasedPropertiesDeep, SetOptional } from 'type-fest';

// By units, requires only qty to be provided
export type CreateRefundRequestItemsByUnits =
  CamelCasedPropertiesDeep<CreateRefundRequestItemByQuantity>[];

// By Amount, only product amount required (no shipping)
export type CreateRefundRequestItemsByAmount =
  | SetOptional<
      CamelCasedPropertiesDeep<CreateRefundRequestItemByAmount>,
      'shippingRefundAmountExpected'
    >[]
  | SetOptional<
      CamelCasedPropertiesDeep<CreateRefundRequestItemByAmount>,
      'productRefundAmountExpected'
    >[];

export type CreateRefundRequestItems =
  | CreateRefundRequestItemsByUnits
  | CreateRefundRequestItemsByAmount;

export type CreateRefundRequest = CamelCasedPropertiesDeep<
  Omit<CreateRefundRequestArgs, 'items'>
> & {
  // To ensure that we send either the qty or the amounts
  items: CreateRefundRequestItems;
};

export const createRefundRequester = ({
  customerOrderId,
  ...args
}: CreateRefundRequest) =>
  ({
    url: `${Routes.refund.create.replace(':customerOrderId', customerOrderId)}`,
    method: RequestMethods.POST,
    body: toSnakeCaseObjectKeys(args),
  } as RequestArgs);

export const useCreateRefund = (options?: {
  // A string used to enable shared results across hook instances which have the same key
  fixedCacheKey?: string;
}) => {
  const [createRefund, response] =
    refundService.useCreateRefundMutation(options);

  return {
    createRefund,
    ...response,
  };
};
