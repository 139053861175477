import { RefundStatus } from 'store/api/entities/refund/models/Refund';

export const refundStatusMapper = (status: string): RefundStatus | null => {
  switch (status) {
    case 'refunded':
      return RefundStatus.refunded;
    case 'aborted':
      return RefundStatus.aborted;
    case 'canceled':
      return RefundStatus.canceled;
    case 'validated':
      return RefundStatus.validated;
    case 'created':
      return RefundStatus.created;
    default:
      return null;
  }
};
