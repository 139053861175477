// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from 'store/api/services';
import Routes from 'store/api/routes';

// Types
import { APIRequestTypes, Service } from 'store/types/api';
import { ISellers, ISellersByOrderIdResponse } from 'store/types/sellers';
import { UIError } from 'store/types/error';

// Constants
import { NameSpaces } from 'constants/namespaces';

// Utils
import { getSellersByOrderIdRequester } from 'store/api/entities/seller/requesters/getSellersByOrderId.requester';
import { getSellerByOrderIdMapper } from 'store/api/entities/seller/mappers/getSellerByOrderIdMapper';

const NAMESPACE = NameSpaces.SELLERS;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getSellersByOrderId: build[APIRequestTypes.QUERY]({
    query: getSellersByOrderIdRequester,
    transformResponse: getSellerByOrderIdMapper,
    providesTags: (response, _error, orderId: string) =>
      response ? [{ type: NAMESPACE, id: orderId }] : [],
  }),

  /**
   * @deprecated
   */
  getSellersByOrderIdLegacy: build[APIRequestTypes.QUERY]({
    query(arg: string) {
      // Get sellers by order id
      const sellersByOrderIdUrl = Routes.sellers.byOrderId.replace(
        ':orderId',
        arg
      );

      return { url: sellersByOrderIdUrl };
    },
    transformResponse: ({ content }: ISellersByOrderIdResponse) => content,
    providesTags: (
      response: ISellers | undefined,
      _error: UIError | undefined,
      args: string
    ) => (response ? [{ type: NAMESPACE, id: args }] : []), // Use the orderId as key for the cache
  }),
});

const sellersService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const {
  useGetSellersByOrderIdQuery,
  useGetSellersByOrderIdLegacyQuery,
} = sellersService;

export type SellersService = typeof sellersService;

export default sellersService;
