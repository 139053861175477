import { FC, ReactNode, MouseEvent } from 'react';
import cx from 'classnames';

import Spinner from 'client/ui/Spinner';

import styles from './Button.module.scss';

/**
 * BUTTONS:
 * ButtonSolidGreen
 * ButtonSolidBlue
 * ButtonGhost
 * ButtonBlank
 */

type ButtonProps = {
  className?: string;
  width?: 'SM' | 'LG';
  height?: 'XS' | 'SM' | 'LG';
  textSize?: 'SM' | 'MD';
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  spinnerColor?: 'green' | 'black';
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
};

const Button: FC<ButtonProps> = ({
  className,
  width = 'SM',
  height = 'SM',
  textSize = 'SM',
  iconStart,
  iconEnd,
  loading,
  disabled,
  spinnerColor,
  onClick,
  children,
  ...props
}) => {
  const buttonClassnames = cx(styles.button, className, {
    [styles.widthSmall]: width === 'SM',
    [styles.widthLarge]: width === 'LG',
    [styles.heightXS]: height === 'XS',
    [styles.heightSmall]: height === 'SM',
    [styles.heightLarge]: height === 'LG',
    [styles.notAllowed]: loading,
  });

  const buttonContentClassnames = cx(styles.buttonContent, {
    [styles.hide]: loading === true,
    [styles.textSizeSmall]: textSize === 'SM',
    [styles.textSizeMedium]: textSize === 'MD',
  });

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    if (disabled || loading) return false;
    onClick?.(e);
  }

  return (
    <button
      className={buttonClassnames}
      {...props}
      onClick={handleClick}
      disabled={disabled}
    >
      {loading && <Spinner color={spinnerColor} size="SM" />}
      <div className={buttonContentClassnames}>
        {iconStart && <span className={styles.iconStart}>{iconStart}</span>}
        {children}
        {iconEnd && <span className={styles.iconEnd}>{iconEnd}</span>}
      </div>
    </button>
  );
};

const ButtonSolidGreen: FC<ButtonProps> = ({ className = '', ...props }) => {
  const classnames = cx(styles.green, {
    [styles.greenDisabled]: props.disabled,
    [styles.greenLoading]: props.loading,
    [className]: className,
  });
  return <Button className={classnames} spinnerColor="black" {...props} />;
};

const ButtonSolidBlue: FC<ButtonProps> = (props) => {
  const classnames = cx(styles.blue, {
    [styles.blueDisabled]: props.disabled,
    [styles.blueLoading]: props.loading,
  });
  return <Button className={classnames} spinnerColor="black" {...props} />;
};

const ButtonSolidWhite: FC<ButtonProps> = ({ className = '', ...props }) => {
  const classnames = cx(styles.white, {
    [styles.white]: props.disabled,
    [styles.white]: props.loading,
    [className]: className,
  });
  return <Button className={classnames} spinnerColor="black" {...props} />;
};

const ButtonGhost: FC<ButtonProps> = ({ className = '', ...props }) => {
  const classnames = cx(styles.ghost, {
    [styles.ghostDisabled]: props.disabled,
    [styles.ghostLoading]: props.loading,
    [className]: className,
  });
  return <Button className={classnames} spinnerColor="black" {...props} />;
};

const ButtonBlank: FC<ButtonProps> = ({ className = '', ...props }) => {
  const classnames = cx(styles.blank, {
    [styles.blankDisabled]: props.disabled,
    [styles.blankLoading]: props.loading,
    [className]: className,
  });
  return <Button className={classnames} spinnerColor="black" {...props} />;
};

const ButtonLink: FC<ButtonProps> = (props) => {
  const classnames = cx(styles.link, {
    [styles.linkDisabled]: props.disabled,
    [styles.linkLoading]: props.loading,
  });

  return <Button className={classnames} spinnerColor="black" {...props} />;
};

export {
  ButtonSolidGreen,
  ButtonSolidBlue,
  ButtonGhost,
  ButtonBlank,
  ButtonSolidWhite,
  ButtonLink,
};
