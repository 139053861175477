import Routes from 'store/api/routes';
import { RequestMethods } from 'store/types/api';
import {
  ApproveExtensionRequestArgs,
  DenyExtensionRequestArgs,
  ExtendRequestArgs,
} from '../contract';

export const approveExtensionRequester = ({
  id,
  ...body
}: ApproveExtensionRequestArgs) => {
  const url = Routes.concreteGuarantee.extensions.approve.replace(
    ':uuid',
    id.toString()
  );

  return {
    url,
    method: RequestMethods.POST,
    body,
  };
};

export const denyExtensionRequester = ({
  id,
  ...body
}: DenyExtensionRequestArgs) => {
  const url = Routes.concreteGuarantee.extensions.deny.replace(
    ':uuid',
    id.toString()
  );

  return {
    url,
    method: RequestMethods.POST,
    body,
  };
};

export const extendRequester = ({ id, ...body }: ExtendRequestArgs) => {
  const url = Routes.concreteGuarantee.extensions.extend.replace(
    ':uuid',
    id.toString()
  );

  return {
    url,
    method: RequestMethods.POST,
    body,
  };
};
