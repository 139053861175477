import Routes from '../../../routes';
import { RequestArgs, RequestMethods } from '../../../../types/api';
import refundService from '../refund';
import { CancelRefundRequest } from './cancelRefund.contract';

export const cancelRefundRequester = ({ refundId }: CancelRefundRequest) =>
  ({
    url: `${Routes.refund.cancel.replace(':refundId', refundId)}`,
    method: RequestMethods.PUT,
  } as RequestArgs);

export const useCancelRefund = () => {
  const [cancelRefund, response] = refundService.useCancelRefundMutation();

  return {
    cancelRefund,
    ...response,
  };
};
