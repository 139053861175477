import { FC, lazy, Suspense } from 'react';

// Styles
import styles from './ScreenDiscriminatorLayout.module.scss';

// Custom Hooks
import { useWindow } from 'client/hooks/features/crossWindow';

// Components
import Spinner from 'client/ui/Spinner';
const WideScreenLayout = lazy(() => import('../WideScreenLayout'));
const ZendeskScreenLayout = lazy(() => import('../ZendeskScreenLayout'));

const ScreenDiscriminatorLayout: FC = () => {
  const { getCurrentView } = useWindow();

  return (
    <div className={styles.layout}>
      <Suspense fallback={<Spinner />}>
        {getCurrentView() === 'widescreen' ? (
          <WideScreenLayout />
        ) : (
          <ZendeskScreenLayout />
        )}
      </Suspense>
    </div>
  );
};

export default ScreenDiscriminatorLayout;
