import { FC, ReactElement } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

const ErrorBoundary: FC<{
  children: ReactElement;
  errorComponent: ReactElement;
}> = ({ children, errorComponent }) => (
  <SentryErrorBoundary fallback={errorComponent}>
    {children}
  </SentryErrorBoundary>
);

export default ErrorBoundary;
