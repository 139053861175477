import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface userState {
  externalId: string;
  email: string;
}

const initialState: userState = {
  externalId: '',
  email: '',
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserProperties: (
      state: userState,
      action: PayloadAction<
        {
          key: keyof userState;
          value: userState[keyof userState];
        }[]
      >
    ) => {
      if (Array.isArray(action.payload)) {
        action.payload.forEach(({ key, value }) => (state[key] = value));
      }

      return state;
    },
  },
});

export const { setUserProperties } = userSlice.actions;
