import { CustomerOrderPaymentMethod } from 'store/api/entities/customerOrders/models';

export const paymentMethodMapper = (
  method: string
): CustomerOrderPaymentMethod | null => {
  switch (method) {
    case 'transfer':
    case 'bank_transfer':
      return 'bank-transfer';
    case 'card':
      return 'card';
    case 'klarna':
      return 'klarna';
    case 'paypal':
      return 'paypal';
    case 'apple_pay':
      return 'apple-pay';
    case 'deferred_payment':
      return 'deferred-payment';
    case 'oney':
      return 'oney3x';
    case 'bank_check':
      return 'check';
    case 'giro_pay':
      return 'giro-pay';
    case 'sofort':
      return 'sofort';
    case 'cb':
      return 'cb';
    case 'visa':
      return 'visa';
    case 'mastercard':
      return 'mastercard';
    case 'amex':
      return 'amex';
    case 'unionpay':
      return 'unionpay';
    case 'other':
      return 'other';
    default:
      return null;
  }
};
