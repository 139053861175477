import { appConfig } from 'config/cv';

export const productImageMapper = (image: string) => {
  if (!image) return '';

  if (image.includes('manomano.com')) {
    if (image.includes('https')) {
      return image;
    } else {
      return `https://${image}`;
    }
  } else {
    return `${appConfig.APP_CDN}${image}`;
  }
};
