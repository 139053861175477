// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from 'store/api/services';
import Routes from 'store/api/routes';

// Types
import { APIRequestTypes, Service } from 'store/types/api';
import { SellerOrder } from 'store/api/entities/order/models/SellerOrder';
import { GetSellerOrderByIdResponse } from 'store/api/entities/order/requesters/getSellerOrderById.contract';

// Constants
import { NameSpaces } from 'constants/namespaces';

// Utils
import { getSellerOrderByIdMapper } from 'store/api/entities/order/mappers';

const NAMESPACE = NameSpaces.ORDERS;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  getOrderById: build[APIRequestTypes.QUERY]({
    query(args: number) {
      const ordersUrl = Routes.orders.byId.replace(':orderId', args.toString());

      return { url: ordersUrl };
    },

    transformResponse: (response, _meta) => {
      return getSellerOrderByIdMapper(response as GetSellerOrderByIdResponse);
    },

    providesTags: (response?: SellerOrder) =>
      response
        ? [
            { type: NAMESPACE, id: response.id },
            { type: NAMESPACE, id: response.reference_number },
          ]
        : [],
  }),
});

const orderService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const { useGetOrderByIdQuery } = orderService;

export type OrderService = typeof orderService;

export default orderService;
