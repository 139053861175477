import { SellerOrderStatus } from 'store/api/entities/order/models/SellerOrder';

export const sellerOrderStatusMapper = (
  status: string
): SellerOrderStatus | null => {
  switch (status) {
    case 'WAITING_PAYMENT':
      return SellerOrderStatus.WAITING_PAYMENT;
    case 'REFUSED':
      return SellerOrderStatus.REFUSED;
    case 'PREPARATION':
      return SellerOrderStatus.PREPARATION;
    case 'SHIPPED':
      return SellerOrderStatus.SHIPPED;
    case 'REFUNDED':
      return SellerOrderStatus.REFUNDED;
    case 'PENDING':
      return SellerOrderStatus.PENDING;
    case 'REFUNDING':
      return SellerOrderStatus.REFUNDING;
    case 'MIGRATED':
      return SellerOrderStatus.MIGRATED;
    default:
      return null;
  }
};
