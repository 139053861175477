import Routes from 'store/api/routes';
import { RequestMethods } from 'store/types/api';
import { IResolveCG } from '../contract';

export const resolveRequester = (arg: IResolveCG) => {
  const cgId = arg.id;
  const url = Routes.concreteGuarantee.close.replace(':cgId', cgId);

  return {
    url,
    method: RequestMethods.POST,
    body: {
      agent_email: arg.agentEmail,
      reason: arg.reason,
    },
  };
};
