import Routes from 'store/api/routes';
import refundService from '../refund';
import { ConfigDetails } from '../../types';
import { GetRefundRemainingRequestArgs } from './getRefundRemaining.contract';

type CamelizedRequestArgs = CamelizeObjectKeys<GetRefundRemainingRequestArgs>;

export const getRefundRemainingRequester = ({
  customerOrderId,
  sellerContractId,
}: CamelizedRequestArgs) => ({
  url: `${Routes.refund.remaining.replace(
    ':customerOrderId',
    customerOrderId
  )}?seller_contract_id=${sellerContractId}`,
});

export const useGetRefundRemaining = (
  config: ConfigDetails<CamelizedRequestArgs>
) => {
  const response = refundService.useGetRefundRemainingQuery(config.args, {
    ...config.options,
  });

  return {
    ...response,
  };
};
