import amex from './amex.svg';
import cb from './cb.svg';
import check from './check.svg';
import deferredPayment from './deferred-payment.svg';
import mastercard from './mastercard.svg';
import oney3x from './oney3x.svg';
import paypal from './paypal.svg';
import sofort from './sofort.svg';
import transfer from './transfer.svg';
import visa from './visa.svg';
import applePay from './applePay.svg';

const PaymentMethods: Record<string, string> = {
  card: cb,
  amex,
  cb,
  check,
  'deferred-payment': deferredPayment,
  mastercard,
  oney3x,
  paypal,
  sofort,
  'bank-transfer': transfer,
  transfer,
  visa,
  'apple-pay': applePay,
};

export default PaymentMethods;
