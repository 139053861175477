import { ConfigDetails } from 'store/api/entities/types';
import { GetRefundsSituationByCustomerOrderIdRequest } from 'store/api/entities/refund/requesters/getRefundsSituationByCustomerOrderId.contract';
import refundService from '../refund';

export const useGetRefundsSituationByCustomerOrderId = (
  config: ConfigDetails<GetRefundsSituationByCustomerOrderIdRequest>
) => {
  const response = refundService.useGetRefundsSituationByCustomerOrderIdQuery(
    config.args,
    {
      ...config.options,
    }
  );

  return {
    ...response,
  };
};
