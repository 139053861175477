import { CreateRefundResponse } from '../requesters';
import { RefundDraft } from 'store/api/entities/refund/models/RefundDraft';
import { priceMapper } from 'store/api/entities/shared/mappers';

export const createRefundMapper = (
  response: CreateRefundResponse
): RefundDraft | undefined => {
  if (!response) return undefined;
  const { content } = response;

  return {
    productAmountRefundedToCustomer: priceMapper(
      content.product_amount_refunded_to_customer?.amount || 0,
      content.product_amount_refunded_to_customer?.currency
    ),
    shippingAmountRefundedToCustomer: priceMapper(
      content.shipping_amount_refunded_to_customer?.amount || 0,
      content.shipping_amount_refunded_to_customer?.currency
    ),
    totalAmountRefundedToCustomer: priceMapper(
      content.total_amount_refunded_to_customer?.amount || 0,
      content.total_amount_refunded_to_customer?.currency
    ),
    couponOnProductRefundedToManoMano: priceMapper(
      content.coupon_on_product_refunded_to_manomano?.amount || 0,
      content.coupon_on_product_refunded_to_manomano?.currency
    ),
    couponOnShippingRefundedToManoMano: priceMapper(
      content.coupon_on_shipping_refunded_to_manomano?.amount || 0,
      content.coupon_on_shipping_refunded_to_manomano?.currency
    ),
    deliveryDiscountRefundedToManoMano: priceMapper(
      content.delivery_discount_refunded_to_manomano?.amount || 0,
      content.delivery_discount_refunded_to_manomano?.currency
    ),
    totalManomanoDiscount: priceMapper(
      content.total_manomano_discount?.amount || 0,
      content.total_manomano_discount?.currency
    ),
    items: content.items.map((item) => ({
      productOrderId: item.product_order_id,
      productAmountRefundedToCustomer: priceMapper(
        item.product_amount_refunded_to_customer?.amount || 0,
        item.product_amount_refunded_to_customer?.currency
      ),
      shippingAmountRefundedToCustomer: priceMapper(
        item.shipping_amount_refunded_to_customer?.amount || 0,
        item.shipping_amount_refunded_to_customer?.currency
      ),
      totalAmountRefundedToCustomer: priceMapper(
        item.total_amount_refunded_to_customer?.amount || 0,
        item.total_amount_refunded_to_customer?.currency
      ),
      couponOnProductRefundedToManoMano: priceMapper(
        item.coupon_on_product_refunded_to_manomano?.amount || 0,
        item.coupon_on_product_refunded_to_manomano?.currency
      ),
      couponOnShippingRefundedToManoMano: priceMapper(
        item.coupon_on_shipping_refunded_to_manomano?.amount || 0,
        item.coupon_on_shipping_refunded_to_manomano?.currency
      ),
      deliveryDiscountRefundedToManoMano: priceMapper(
        item.delivery_discount_refunded_to_manomano?.amount || 0,
        item.delivery_discount_refunded_to_manomano?.currency
      ),
      totalManomanoDiscount: priceMapper(
        item.total_manomano_discount?.amount || 0,
        item.total_manomano_discount?.currency
      ),
    })),
  };
};
