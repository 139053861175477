// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from '../../services';
import Routes from '../../routes';
import { cacheDuration } from '../../base/cache';

// Types
import { APIRequestTypes, Service } from 'store/types/api';
import { searchSellerOrdersMapper } from './mappers';
import {
  SearchOrdersRequest,
  SearchOrdersResponse,
} from 'store/api/entities/order/requesters/searchSellerOrders.contract';

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  searchOrders: build[APIRequestTypes.QUERY]({
    query(filters: SearchOrdersRequest) {
      const ordersUrl = Routes.orders.search;
      const searchParams = new URLSearchParams(filters);

      return { url: `${ordersUrl}?${searchParams}` };
    },
    keepUnusedDataFor: cacheDuration.getOrders,
    transformResponse: (response: SearchOrdersResponse) =>
      searchSellerOrdersMapper(response),
  }),
});

const ordersService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const { useSearchOrdersQuery } = ordersService;

export type OrdersService = typeof ordersService;

export default ordersService;
