import React from 'react';
import cx from 'classnames';

// Assets
import { SpinnerBlack, SpinnerGreen } from 'client/assets/icons';

// Styles
import styles from './Spinner.module.scss';

type SpinnerProps = {
  color?: 'green' | 'black';
  size?: 'SM' | 'LG';
};

const Spinner: React.FC<SpinnerProps> = ({ color = 'green', size = 'LG' }) => {
  const icons = {
    green: SpinnerGreen,
    black: SpinnerBlack,
  };

  const classnames = cx(styles.spinnerImg, {
    [styles.small]: size === 'SM',
    [styles.large]: size === 'LG',
  });

  return (
    <div className={styles.spinner}>
      <div className={classnames}>
        <img src={icons[color]} alt="Loading" />
      </div>
    </div>
  );
};

export default Spinner;
