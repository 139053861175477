import { createApi } from '@reduxjs/toolkit/query/react';
import { requestBuilder } from './requestBuilder';
import { IRTKQueryApi } from '../../types/api';

export const createRTKQueryApi = ({
  reducerPath,
  serviceProvider,
  tagTypes,
}: IRTKQueryApi) =>
  createApi({
    baseQuery: requestBuilder({ serviceProvider }),
    endpoints: () => ({}),
    reducerPath,
    tagTypes,
  });
