import { cacheDuration } from 'store/api/base/cache';
import { ConfigDetails } from 'store/api/entities/types';
import { parseUrlSearchParamsToSnakeCaseObject } from 'utils/parsers';
import { useSearchCustomerOrdersQuery } from '../customerOrders';
import { SearchCustomerOrdersRequest } from 'store/api/entities/customerOrders/requesters/searchCustomerOrders.contract';

export const useSearchCustomerOrders = (
  config: ConfigDetails<SearchCustomerOrdersRequest>
) => {
  const args =
    parseUrlSearchParamsToSnakeCaseObject<SearchCustomerOrdersRequest>(
      config.args
    );

  const response = useSearchCustomerOrdersQuery(
    {
      ...args,
      page: args.page || 1,
      limit: args.limit || 10,
    } as unknown as SearchCustomerOrdersRequest,
    {
      ...config.options,
    }
  );

  return {
    ...response,
    fetchCache: cacheDuration.getCustomerOrders,
  };
};
