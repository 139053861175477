import de from './de.svg';
import es from './es.svg';
import fr from './fr.svg';
import gb from './gb.svg';
import it from './it.svg';
import be from './be.svg';
import lu from './lu.svg';

const Flags: Record<string, string> = {
  de,
  es,
  fr,
  gb,
  it,
  be,
  lu,
};

export default Flags;
