// RTK Query
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// API
import { msfApi } from '../../services';
import Routes from '../../routes';

// Types
import { APIRequestTypes, Service } from '../../../types/api';
import {
  SearchCustomerOrdersRequest,
  SearchCustomerOrdersResponse,
} from 'store/api/entities/customerOrders/requesters/searchCustomerOrders.contract';

// Adapters & Mappers
import {
  searchCustomerOrderProductsMapper,
  searchCustomerOrdersMapper,
} from './mappers';
import { searchCustomerOrderProductsRequester } from './requesters/searchCustomerOrderProducts.requester';

// Constants
import { NameSpaces } from '../../../../constants/namespaces';
import { cacheDuration } from '../../base/cache';

const NAMESPACE = NameSpaces.CORT;

const endpoints = (build: EndpointBuilder<Service, string, string>) => ({
  searchCustomerOrders: build[APIRequestTypes.QUERY]({
    query(filters: SearchCustomerOrdersRequest) {
      const ordersUrl = Routes.customerOrders.search;
      const searchParams = new URLSearchParams(filters);

      return { url: `${ordersUrl}?${searchParams}` };
    },
    keepUnusedDataFor: cacheDuration.getCustomerOrders,
    transformResponse: (response: SearchCustomerOrdersResponse) =>
      searchCustomerOrdersMapper(response),
    providesTags: (_response, _error, args) => [
      { type: NAMESPACE, id: new URLSearchParams(args).toString() },
    ],
  }),

  searchCustomerOrderProducts: build[APIRequestTypes.QUERY]({
    query: searchCustomerOrderProductsRequester,
    transformResponse: searchCustomerOrderProductsMapper,
    keepUnusedDataFor: cacheDuration.getCustomerOrders,
    providesTags: (_response, _error, args) => [
      {
        type: NAMESPACE,
        id: new URLSearchParams({
          seller_contract_ids: args.seller_contract_ids.join(','),
        }).toString(),
      },
    ],
  }),
});

const transactionService = msfApi.injectEndpoints({
  endpoints: endpoints,
  overrideExisting: false,
});

export const {
  useSearchCustomerOrdersQuery,
  useSearchCustomerOrderProductsQuery,
} = transactionService;

export type TransactionService = typeof transactionService;

export default transactionService;
