import { NameSpaces } from 'constants/namespaces';
import { ServiceProviders } from 'store/types/api';
import { RTKQuery } from '../base';

const { USER, TICKET } = NameSpaces;

const zafApi = RTKQuery.createRTKQueryApi({
  serviceProvider: ServiceProviders.ZAF,
  reducerPath: 'zafApi',
  tagTypes: [USER, TICKET],
});

export default zafApi;
