// Assets
import { ManoManoLogo } from 'client/assets/icons';

// Components
import { ButtonSolidGreen } from 'client/ui/Button';

// Utils
import { isFunction } from 'utils/validations';

// Styles
import styles from './ErrorComponent.module.scss';

const ErrorComponent = ({ onClickGoBack }: { onClickGoBack?: () => void }) => {
  const handleGoBack = () =>
    isFunction(onClickGoBack) ? onClickGoBack?.() : window.location.reload();

  return (
    <div className={styles.container}>
      <figure>
        <ManoManoLogo />
      </figure>
      <p className={styles.text}>Oops! An error has occurred.</p>
      <ButtonSolidGreen onClick={handleGoBack}>Go Back</ButtonSolidGreen>
    </div>
  );
};

export default ErrorComponent;
