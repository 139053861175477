import Routes from 'store/api/routes';
import {
  GetConcreteGuaranteeByIdArgs,
  GetConcreteGuaranteeByUUIDArgs,
} from '../contract';
import { ConfigDetails } from '../../types';
import concreteGuaranteeService from '../concreteGuarantee';
import { useSearchCustomerOrderProducts } from '../../customerOrders/requesters';
import { UUID } from 'crypto';
import { ConcreteGuaranteeDetail, ConcreteGuaranteeDetails } from '../mappers';

export const byUUIDRequester = ({ id }: GetConcreteGuaranteeByIdArgs) => ({
  url: Routes.concreteGuarantee.byId.replace(':uuid', id),
});

export const useGetConcreteGuaranteeByUUID = (
  config: ConfigDetails<GetConcreteGuaranteeByUUIDArgs>
) => {
  const response = concreteGuaranteeService.useGetConcreteGuaranteeByIdQuery(
    config.args,
    {
      ...config.options,
    }
  );

  const { data: customerOrderProducts } = useSearchCustomerOrderProducts({
    args: {
      customer_order_id: config.args.customerOrderId as UUID,
      seller_contract_ids:
        response.data && response.data.seller?.id
          ? [response.data.seller.id]
          : [],
    },
    options: {
      skip:
        !response.isSuccess ||
        !response.data ||
        !config.args.customerOrderId ||
        !response.data.seller.id,
    },
  });

  // Populate products with title and image from customer order service
  const getDetails = (details: ConcreteGuaranteeDetails) => {
    return details.map((detail) => ({
      ...detail,
      product: getProductWithImageAndTitle(detail.product),
    }));
  };

  const getProductWithImageAndTitle = (
    product: ConcreteGuaranteeDetail['product']
  ) => {
    const customerOrderProduct = customerOrderProducts?.find(
      (item) => item.id === product.id
    );

    return {
      ...product,
      thumbnail: customerOrderProduct?.image,
      title: customerOrderProduct?.title,
    };
  };

  return {
    ...response,
    data: response.data
      ? {
          ...response.data,
          details: getDetails(response.data.details),
        }
      : response.data,
  };
};
