import Routes from '../../../routes';
import { SearchCustomerOrderProductsRequestArgs } from './searchCustomerOrderProducts.contract';
import { ConfigDetails } from '../../types';
import { useSearchCustomerOrderProductsQuery } from '../customerOrders';

export const searchCustomerOrderProductsRequester = ({
  customer_order_id,
  seller_contract_ids,
}: SearchCustomerOrderProductsRequestArgs) => {
  const searchParams = new URLSearchParams({
    seller_contract_ids: seller_contract_ids.join(','),
  });

  return {
    url: `${Routes.customerOrders.searchProducts.replace(
      ':cortId',
      customer_order_id
    )}?${searchParams}`,
  };
};

export const useSearchCustomerOrderProducts = (
  config: ConfigDetails<SearchCustomerOrderProductsRequestArgs>
) => {
  const response = useSearchCustomerOrderProductsQuery(config.args, {
    ...config.options,
  });

  return {
    ...response,
  };
};
