import {
  GetRefundsSituationByCustomerOrderIdProductResponse,
  GetRefundsSituationByCustomerOrderIdResponse,
} from 'store/api/entities/refund/requesters/getRefundsSituationByCustomerOrderId.contract';
import { RefundSituation } from 'store/api/entities/refund/models/RefundSituation';
import { refundStatusMapper } from 'store/api/entities/refund/mappers/refundStatusMapper';
import { priceMapper } from 'store/api/entities/shared/mappers';
import { refundChannelMapper } from './refundChannelMapper';

export const refundsSituationByCustomerOrderIdMapper = ({
  content,
}: GetRefundsSituationByCustomerOrderIdResponse): RefundSituation[] => {
  return content.map((refund) => ({
    id: refund.id,
    isFullRefund: refund.is_full_refund,
    affectsOtherSellerOrders: refund.affects_other_seller_orders,
    date: refund.created_at,
    shippingRefundedToCustomer: priceMapper(
      refund.shipping_refunded_to_customer.amount,
      refund.shipping_refunded_to_customer.currency
    ),
    totalRefundedToCustomer: priceMapper(
      refund.total_refunded_to_customer.amount,
      refund.total_refunded_to_customer.currency
    ),
    refundChannel: refundChannelMapper(refund.channel),
    status: refundStatusMapper(refund.status),
    isCancelable: refund.is_cancelable,
    reason: refund.reason,
    requestedBy: refund.requested_by,
    isByAmount:
      !refund.is_full_refund && checkIsRefundByAmount(refund.products),
    products: refund.products.map((product) => ({
      productOrderId: product.product_order_id,
      title: product.title,
      image: product.image,
      quantity: product.quantity || 0,
      productAmountRefundedToCustomer: priceMapper(
        product.product_amount_refunded_to_customer?.amount,
        product.product_amount_refunded_to_customer?.currency
      ),
    })),
  }));
};

export const checkIsRefundByAmount = (
  products: GetRefundsSituationByCustomerOrderIdProductResponse[] | undefined
) =>
  !products?.length ||
  products.some(({ quantity }) => !quantity || quantity === 0) ||
  false;
