import { PaginatedData } from '../../types';
import { paymentMethodMapper } from 'store/api/entities/customerOrders/mappers/paymentMethodMapper';
import { paymentStatusMapper } from './paymentStatusMapper';
import {
  SearchCustomerOrderPaymentByCardDetails,
  SearchCustomerOrderPaymentByTransferDetails,
  SearchCustomerOrdersResponse,
} from 'store/api/entities/customerOrders/requesters/searchCustomerOrders.contract';
import { CustomerOrderForSearchTable } from 'store/api/entities/customerOrders/models';
import { priceMapper } from 'store/api/entities/shared/mappers';

export const searchCustomerOrdersMapper = (
  response: SearchCustomerOrdersResponse
): PaginatedData<CustomerOrderForSearchTable> => {
  return {
    content: response.content.map((order) => {
      // Let's assume that the card brand is always present, if not we don't have to worry about it
      // because the payment method will be used instead and the payment method mapper is responsible
      // for handling the unknown payment methods.
      const { brand: cardBrand, o3D_secure_result } =
        (order.payment?.details as SearchCustomerOrderPaymentByCardDetails) ??
        {};

      return {
        references: {
          cortId: order.id,
          cortHrr: order.hrr,
          transactionId: order.transaction_id ?? null,
        },

        date: order.created_at,
        customer: {
          id: order.customer?.id,
          fullName: order.customer?.full_name,
          email: order.customer?.email,
        },

        payment: {
          method: paymentMethodMapper(cardBrand ?? order.payment?.method),
          status: paymentStatusMapper(order.payment?.status),
          o3dResponse: o3D_secure_result ?? null,
        },

        payments: order.payments.map((payment) => {
          const { brand: cardBrand, o3D_secure_result } =
            (payment.details as SearchCustomerOrderPaymentByCardDetails) ?? {};

          const commonDetails = {
            method: paymentMethodMapper(cardBrand ?? payment.method),
            status: paymentStatusMapper(payment.status),
          };

          if (payment.method === 'bank_transfer') {
            const { bic, iban } =
              (payment?.details as SearchCustomerOrderPaymentByTransferDetails) ??
              {};

            return {
              ...commonDetails,
              bic,
              iban,
            };
          }

          return {
            ...commonDetails,
            o3dResponse: o3D_secure_result ?? null,
          };
        }),

        iban:
          (
            order.payment
              ?.details as SearchCustomerOrderPaymentByTransferDetails
          )?.iban ?? null,

        financial: {
          shippingAmount: order.financial?.shipping_amount && {
            excludingVat: priceMapper(
              order.financial.shipping_amount.excluding_vat,
              order.financial.shipping_amount.currency
            ),
            includingVat: priceMapper(
              order.financial.shipping_amount.including_vat,
              order.financial.shipping_amount.currency
            ),
          },
          productAmount: order.financial?.product_amount && {
            excludingVat: priceMapper(
              order.financial.product_amount.excluding_vat,
              order.financial.product_amount.currency
            ),
            includingVat: priceMapper(
              order.financial.product_amount.including_vat,
              order.financial.product_amount.currency
            ),
          },
          subtotalAmount: order.financial?.subtotal_amount && {
            excludingVat: priceMapper(
              order.financial.subtotal_amount.excluding_vat,
              order.financial.subtotal_amount.currency
            ),
            includingVat: priceMapper(
              order.financial.subtotal_amount.including_vat,
              order.financial.subtotal_amount.currency
            ),
          },
          shippingDiscountAmount: order.financial?.shipping_discount_amount && {
            includingVat: priceMapper(
              order.financial.shipping_discount_amount.including_vat,
              order.financial.shipping_discount_amount.currency
            ),
          },
          totalAmount: order.financial?.total_amount && {
            excludingVat: priceMapper(
              order.financial.total_amount.excluding_vat,
              order.financial.total_amount.currency
            ),
            includingVat: priceMapper(
              order.financial.total_amount.including_vat,
              order.financial.total_amount.currency
            ),
          },
          coupon: order.financial?.coupon && {
            totalAmount: order.financial?.coupon?.total_amount && {
              includingVat: priceMapper(
                order.financial.coupon.total_amount.including_vat,
                order.financial.coupon.total_amount.currency
              ),
            },
            coupons: order.financial?.coupon?.coupons.map((coupon) => ({
              code: coupon.code,
              percent: coupon.percent,
            })),
          },
        },

        orders: order.orders,
      };
    }),
    pagination: response.pagination,
  };
};
