import { Price } from 'store/api/entities/shared/models';

/**
 * Price mapper
 * @param price it can be a Decimal or a string, in case it is a string, it will be converted to a float number. Integers are not supported.
 * @param currency
 */
export const priceMapper = <TCurrency = string>(
  price: Decimal | string,
  currency = 'EUR'
): Price<TCurrency> => {
  // Convert the price to a float number, in case it is a string
  const priceAsNumber = typeof price === 'string' ? parseFloat(price) : price;

  // If the price has decimals, we will consider only two positions
  // Multiplying by 100 to convert to the requested format
  const intAmount = Math.round(priceAsNumber * 100);
  const amount = intAmount / 100;

  return {
    amount,
    intAmount,
    currency: currency as TCurrency,
  };
};
