import { UUID } from 'crypto';
import { Price } from 'store/api/entities/shared/models';

export interface SellerOrder {
  id: number;
  reference_number: string;
  date: string;
  transaction_id: number;
  customer_order_id: string;
  customer_order_reference: string | null;
  status: SellerOrderStatus | null;
  customer_id: number;
  country_iso: string;
  is_mmf: boolean;
  is_mfx: boolean;
  currency: string;
  total_price: Price;
  total_price_excluding_vat: Price;
  total_price_vat: Price;
  shipping_price: Price;
  shipping_price_excluding_vat: Price;
  shipping_price_vat_rate: Price;
  total_discount: Price;
  shipping_discount: Price;
  products: SellerOrderProduct[];
  last_concrete_guarantee_status: string | null;
  manomano_coupon_on_product?: Price;
  manomano_coupon_on_shipping?: Price;
  manomano_delivery_discount?: Price;
  seller_coupon_on_product?: Price;
  total_amount_paid_by_the_customer?: Price;
}

export enum SellerOrderStatus {
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  REFUSED = 'REFUSED',
  PREPARATION = 'PREPARATION',
  SHIPPED = 'SHIPPED',
  REFUNDED = 'REFUNDED',
  PENDING = 'PENDING',
  REFUNDING = 'REFUNDING',
  MIGRATED = 'MIGRATED',
}

export interface SellerOrderProduct {
  id: number;
  title: string;
  image: string;
  description: string;
  order_item_quantity: number;
  currency: string;
  price_ttc: Price;
  price_htc: Price;
  price_total_ttc: Price;
  total_amount_paid_by_the_customer?: Price; // in v2 this is not coming, since v3+
  product_order_id?: UUID; // in v2 this is not coming, since v3+
  seller: {
    id: number;
    name: string;
  };
  seller_sku: string;
  me_reference: string;
  logistics?: {
    logistic_id: string;
    ean?: string; // in v2 this is not coming, since v3+
    height: number;
    weight_gross: number;
    weight_net: number;
    width: number;
    depth: number;
    volume: number;
  };
  selected?: boolean;
  remaining_quantity?: number;
  selectedQty?: number;
}
