const Routes = Object.freeze({
  // plop-inject:route
  customer: {
    byIdOld: '/api/v2/customer360/customers/identifier/:customerId',
    byId: '/api/v2/customer360/customers/:customerId',
    resetPassword:
      '/api/v2/customer360/customers/send-reset-password-email/:email',
    customersByName:
      '/api/v2/customer360/customers/search?page=:page&limit=:limit&firstname=:firstname&lastname=:lastname',
    customersByEmail:
      '/api/v2/customer360/customers/search?page=:page&limit=:limit&email=:email',
    activate: '/api/v2/customer360/customers/:id/activate',
    deactivate: '/api/v2/customer360/customers/:id/deactivate',
  },
  orders: {
    search: '/api/v2/customer360/orders/search',
    byId: '/api/v3/customer360/orders/:orderId',
  },
  customerOrders: {
    byId: '/api/v2/customer360/customer-orders/:cortId',
    search: '/api/v2/customer360/customer-orders/search',
    searchProducts: '/api/v2/customer360/customer-orders/:cortId/products',
  },
  sellers: {
    byOrderId: '/api/v2/customer360/orders/:orderId/sellers',
  },
  messages: {
    byOrderId: '/api/v2/customer360/orders/:orderId/messages',
  },
  delivery: {
    byOrderId: '/api/v3/customer360/delivery/:orderId',
  },
  invoice: {
    invoices: '/api/v2/customer360/order-invoices/:orderId/invoices',
    claims:
      '/api/v2/customer360/order-invoices/:orderId/sellers/:sellerId/invoices',
  },
  refund: {
    byCustomerOrderId:
      '/api/v2/customer360/customer-orders/:customerOrderId/refunds',
    reasons: '/api/v2/customer360/customer-orders/refunds/reasons',
    remaining:
      '/api/v2/customer360/customer-orders/:customerOrderId/refunds/remaining',
    create: '/api/v2/customer360/customer-orders/:customerOrderId/refunds',
    cancel: '/api/v2/customer360/refunds/:refundId/cancel',
  },
  concreteGuarantee: {
    byOrderId: '/api/v2/customer360/orders/:orderId/concrete-guarantees',
    close: '/api/v2/customer360/concrete-guarantees/:cgId/solve',
    motives: '/api/v2/customer360/concrete-guarantees/open-reasons',
    create: '/api/v3/customer360/concrete-guarantees',
    delete: '/api/v2/customer360/concrete-guarantees/:cgId',
    byId: '/api/v2/customer360/concrete-guarantees/:uuid',
    extensions: {
      approve:
        '/api/v2/customer360/concrete-guarantees/:uuid/extension/approve',
      deny: '/api/v2/customer360/concrete-guarantees/:uuid/extension/deny',
      extend: '/api/v2/customer360/concrete-guarantees/:uuid/extension/extend',
    },
    appeal: {
      approve: '/api/v2/customer360/concrete-guarantees/:uuid/appeal/approve',
      deny: '/api/v2/customer360/concrete-guarantees/:uuid/appeal/deny',
    },
  },
  coupons: {
    byCustomerId: '/api/v2/customer360/coupons/:customerId',
  },
  return: {
    byOrderId: '/api/v2/customer360/returns/:orderId',
  },
  config: {
    newRefundSystem:
      'https://browserapi.manomano.com/api/v1/platform-config/platform/FR/name/newrefundsystem',
  },
});

export default Routes;
