import { UUID } from 'crypto';
import { Price } from '../../shared/models';

export enum RefundStatus {
  refunded = 'refunded',
  aborted = 'aborted',
  validated = 'validated',
  canceled = 'canceled',
  created = 'created',
}

export enum RefundType {
  full = 'FULL',
  byItem = 'BY_ITEM',
  byAmount = 'BY_AMOUNT',
}

export enum RefundChannel {
  extremeCase = 'WIDEVIEW_EXTREME_CASE',
  agentRefund = 'WIDEVIEW_AGENT_REFUND',
  wideviewConcreteGuarantee = 'WIDEVIEW_CONCRETE_GUARANTEE',
  toolboxReturnPage = 'TOOLBOX_RETURN_PAGE',
  toolboxConcreteGuarantee = 'TOOLBOX_CONCRETE_GUARANTEE_PAGE',
  toolboxOrderDetailsPage = 'TOOLBOX_ORDER_DETAILS_PAGE',
  sellerApiCall = 'SELLER_API_CALL',
  paymentBackOffice = 'PAYMENT_BACK_OFFICE',
  legacyBackOffice = 'LEGACY_BACK_OFFICE',
  oneStock = 'ONE_STOCK',
}

export enum RefundLiable {
  manomano = 'MANOMANO',
  seller = 'SELLER',
}

export enum AgentRefundReason {
  cancelledBeforeShipment = 'CANCELLED_BEFORE_SHIPMENT',
  delayed = 'DELAYED',
  cancelledAfterDelivery = 'CANCELLED_AFTER_DELIVERY',
  deliveryProblemPackageReturned = 'DELIVERY_PROBLEM_PACKAGE_RETURNED',
  packageLost = 'PACKAGE_LOST',
  packageLostSentButNotReceivedByTheCarrier = 'PACKAGE_LOST_SENT_BUT_NOT_RECEIVED_BY_THE_CARRIER',
  unsuccessfulDelivery = 'UNSUCCESSFUL_DELIVERY',
  deliveryTimeExceededInPointOfDelivery = 'DELIVERY_TIME_EXCEEDED_IN_POINT_OF_DELIVERY',
  deliveryDispute = 'DELIVERY_DISPUTE',
  withdrawal = 'WITHDRAWAL',
  damagedProductUnderWarranty30Days = 'DAMAGED_PRODUCT_UNDER_WARRANTY_30DAYS',
  damagedProductWithoutReturn = 'DAMAGED_PRODUCT_WITHOUT_RETURN',
  damagedProductWithReturn = 'DAMAGED_PRODUCT_WITH_RETURN',
  damagedPackageDeliveryRefused = 'DAMAGED_PACKAGE_DELIVERY_REFUSED',
  packageReturnedDueToDeliveryProblem = 'PACKAGE_RETURNED_DUE_TO_DELIVERY_PROBLEM',
  nonCompliantProductIncorrectDescription = 'NON_COMPLIANT_PRODUCT_INCORRECT_DESCRIPTION',
  nonCompliantProductMissingSparePart = 'NON_COMPLIANT_PRODUCT_MISSING_SPARE_PART',
  nonCompliantProductWrongLabelEanCorrespondence = 'NON_COMPLIANT_PRODUCT_WRONG_LABEL_EAN_CORRESPONDENCE',
  nonCompliantProductLogisticsMistake = 'NON_COMPLIANT_PRODUCT_LOGISTICS_MISTAKE',
  incompleteOrder = 'INCOMPLETE_ORDER',
  emptyPackage = 'EMPTY_PACKAGE',
  concreteGuarantee = 'CONCRETE_GUARANTEE',
}

export enum ManoManoRefundReason {
  hypercare = 'HYPERCARE',
  deliveryIssue = 'DELIVERY_ISSUE',
  manoManoMistake = 'MANOMANO_MISTAKE',
  deliveredBrokenOrDamaged = 'DELIVERED_BROKEN_OR_DAMAGED',
  productNotAsDescribed = 'PRODUCT_NOT_AS_DESCRIBED',
  warrantyCoverage = 'WARRANTY_COVERAGE',
  returnIssue = 'RETURN_ISSUE',
  legalRequirement = 'LEGAL_REQUIREMENT',
}

export type RefundReason = AgentRefundReason | ManoManoRefundReason;

export type RefundItemByQuantity = {
  productOrderId: UUID;
  includeShippingAmount: boolean;
  productRefundAmountExpected?: never;
  shippingRefundAmountExpected?: never;
  quantity: Integer;
};

export type RefundItemByAmount = {
  productOrderId: UUID;
  includeShippingAmount: boolean;
  productRefundAmountExpected?: Price;
  shippingRefundAmountExpected?: Price;
  quantity?: never;
};

export type RefundItem = RefundItemByQuantity | RefundItemByAmount;
