import { RefundChannel } from '../models';

export const refundChannelMapper = (channel: string): RefundChannel | null => {
  switch (channel.toUpperCase()) {
    case 'WIDEVIEW_EXTREME_CASE':
      return RefundChannel.extremeCase;
    case 'WIDEVIEW_AGENT_REFUND':
      return RefundChannel.agentRefund;
    case 'TOOLBOX_CONCRETE_GUARANTEE_PAGE':
      return RefundChannel.toolboxConcreteGuarantee;
    case 'TOOLBOX_ORDER_DETAILS_PAGE':
      return RefundChannel.toolboxOrderDetailsPage;
    case 'WIDEVIEW_CONCRETE_GUARANTEE':
      return RefundChannel.wideviewConcreteGuarantee;
    case 'SELLER_API_CALL':
      return RefundChannel.sellerApiCall;
    case 'PAYMENT_BACK_OFFICE':
      return RefundChannel.paymentBackOffice;
    case 'LEGACY_BACK_OFFICE':
      return RefundChannel.legacyBackOffice;
    case 'ONE_STOCK':
      return RefundChannel.oneStock;
    default:
      return null;
  }
};
