import Routes from 'store/api/routes';
import { RequestMethods } from 'store/types/api';
import { ICreateCG } from '../contract';

export const openRequester = (arg: ICreateCG) => {
  const url = Routes.concreteGuarantee.create;

  return {
    url,
    method: RequestMethods.POST,
    body: arg,
  };
};
