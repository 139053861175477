import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { ITicketOrderResponse, ITicketResponse } from 'store/types/ticket';
import { ICurrentUserResponse } from 'store/types/user';
import {
  ICustomerResponse,
  ICustomerResponseOld,
  SearchCustomersResponse,
} from 'store/types/customer';
import { IOrdersResponse } from 'store/types/orders';
import { ISellersByOrderIdResponse } from 'store/types/sellers';
import { IMessagesByOrderIdResponse } from 'store/types/messages';
import {
  ITransactionResponse,
  SearchTransactionsResponse,
} from 'store/types/transaction';
import { IDeliveryResponse } from './delivery';
import { IInvoiceResponse } from 'store/types/invoice';
import { ICouponsResponse } from 'store/types/coupons';
import { UIError } from 'store/types/error';
import {
  IConcreteGuaranteeByUUIDResponse,
  IConcreteGuaranteesByOrderIdResponse,
  IConcreteGuarantesCategoriesResponse,
  IConcreteGuarantesSolutionsResponse,
} from 'store/api/entities/concreteGuarantee/contract';
import { IReturnResponse } from './return';
import { ICortResponse } from './cort';
import { GetSellerOrderByIdResponse } from 'store/api/entities/order/requesters/getSellerOrderById.contract';
import { SearchOrdersResponse } from 'store/api/entities/order/requesters/searchSellerOrders.contract';
import { SearchCustomerOrdersResponse } from 'store/api/entities/customerOrders/requesters/searchCustomerOrders.contract';
import { GetRefundsSituationByCustomerOrderIdResponse } from 'store/api/entities/refund/requesters/getRefundsSituationByCustomerOrderId.contract';
import {
  CreateRefundResponse,
  GetRefundReasonsResponse,
  GetRefundRemainingResponse,
} from 'store/api/entities/refund/requesters';
import { SearchCustomerOrderProductsResponse } from '../api/entities/customerOrders/requesters/searchCustomerOrderProducts.contract';

export enum APIRequestTypes {
  QUERY = 'query',
  MUTATION = 'mutation',
}

export enum ServiceProviders {
  MSF = 'msf',
  ZAF = 'zaf',
  PUBLIC = 'public',
}

export enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface IRTKQueryApi {
  serviceProvider: ServiceProviders;
  reducerPath: string;
  tagTypes?: string[];
}

export type ServerResponses =
  | ITicketOrderResponse
  | ITicketResponse
  | ICurrentUserResponse
  | ICustomerResponseOld
  | ICustomerResponse
  | IOrdersResponse
  | ICortResponse
  | ITransactionResponse
  | ISellersByOrderIdResponse
  | IMessagesByOrderIdResponse
  | IDeliveryResponse
  | IInvoiceResponse
  | IConcreteGuaranteesByOrderIdResponse
  | IConcreteGuaranteeByUUIDResponse
  | IConcreteGuarantesCategoriesResponse
  | ICouponsResponse
  | SearchCustomersResponse
  | IReturnResponse
  | SearchTransactionsResponse
  | IConcreteGuarantesSolutionsResponse
  | SearchOrdersResponse
  | SearchCustomerOrdersResponse
  | GetSellerOrderByIdResponse
  | GetRefundsSituationByCustomerOrderIdResponse
  | GetRefundReasonsResponse
  | GetRefundRemainingResponse
  | CreateRefundResponse
  | SearchCustomerOrderProductsResponse;

export type RequestArgs = {
  url: string;
  method?: RequestMethods;
  body?: Record<string, unknown>;
  headers?: Record<string, unknown>;
};

export type Service = BaseQueryFn<
  RequestArgs, // Args
  ServerResponses, // Result
  UIError | undefined // Error
>;
